<template>
  <div class="mt-5 is-flex is-justify-content-space-between">

    <div class="has-background-white px-4 py-4 main-inner-set box-indent">
      <p class="is-uppercase heading-text-size has-text-grey has-text-weight-medium mb-3">Редактировать Филиала</p>
      <p class="mb-3">Введите данные</p>

      <b-field
        label="Компания"
        class="mt-3"
      >
        <div v-if="branch.company">
          <b-select
            placeholder="Выберите филиал"
            v-model="branch.company.id"
          >
            <option
              v-for="option of companies"
              :value="option.id"
              selected
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </div>
      </b-field>
      <b-field label="Телеграм канал">
        <b-input
          type="text"
          v-model.trim="branch.telegram_chanel"
          placeholder="ID телеграм канала"
          required
        />
      </b-field>
      <label>
        <b>Название филиала</b>
      </label>
      <input
        class="input"
        type="text"
        placeholder="Название филиала"
        v-model="branch.name"
      >

      <div class="mt-5">
        <button
          class="button is-info"
          @click="save"
        >Сохранить
        </button>
        <button class="button is-warning mx-3">Деактивировать</button>
        <button
          class="button is-danger"
          @click="deleteBranch"
        >Удалить
        </button>
      </div>
    </div>

    <div class="has-background-white px-4 py-4 main-inner-set">
      <h4>СПИСОК ЗАВОДОВ</h4>
      <ul
        v-for="item of factories"
        :key="item.id"
        class="ml-5 list"
      >
        <li>
          <a @click="editFactory(item.id)">{{ item.name }}</a>
        </li>
      </ul>
      <router-link
        class="has-text-weight-medium"
        to="/factory-registration"
      >Зарегистрировать завод
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      branch_id: undefined,
      branch: [],
      companies: [],
      factories: [],
    };
  },
  created() {
    this.getBranch();
    this.fetchCompanies();
    this.fetchBranchFactories();
  },
  mounted() {
  },
  methods: {
    async getBranch() {
      this.branch_id = this.$router.history.current.params.id.replace(/[^0-9]/g, "");
      try {
        const { data } = await this.$axios.get(`admin/branches/${this.branch_id}`)
        this.branch = data?.data || {}
      } catch (e) {
        throw new Error(e)
      }
    },
    async deleteBranch() {
      try {
        await this.$axios.delete(`admin/branches/${this.branch_id}`)
        await this.$router.push("/filials-list");
      } catch (e) {
        throw new Error(e)
      }
    },
    async fetchCompanies() {
      try {
        const { data } = await this.$axios.get(`admin/companies`)
        this.companies = data?.data || []
      } catch (e) {
        throw new Error(e)
      }
    },
    async fetchBranchFactories() {
      try {
        const { data } = await this.$axios.get(`admin/branches/${this.branch_id}/factories`)
        this.factories = data?.data || []
      } catch (e) {
        throw new Error(e)
      }
    },
    async save() {
      try {
        const { data } = await this.$axios.put(`admin/branches/${this.branch_id}`, {
          name: this.branch.name,
          company_id: this.branch.company.id,
          telegram_chanel: this.branch.telegram_chanel
        })
        if (data?.code === 201) this.$router.push("/filials-list")
      } catch (e) {
        throw new Error(e)
      }
    },
    editFactory(factory_id) {
      this.$router.push(`/factory-editor/${factory_id}`);
    },
  },
};
</script>

<style>
.main-inner-set {
  flex: 1;
}

.list {
  list-style: disc;
}

.box-indent {
  margin-right: 40px;
}

</style>
